<template>
  <BaseModal
    @closeModal="$emit('close')"
    :bgClose="true"
    :cancel="false"
    :escCancel="true"
    @ok="$emit('close')"
  >
    <template #header>Raport: prowizje niewypłacone</template>
    <template #default>
      <div class="font-semibold underline">Należność na dzień</div>
      <p class="text-sm">
        data, do której brane są pod uwagę należności rat. Datę tę należy ustawić w taki sposób, aby uwzględnić należności, którezostały już zarejestrowana przez
        TU i w związku z tym nastapiło zobowiązanie prowizyjne. Domyślnie jest to ostatni dzień poprzedniego miesiąca.
      </p>
      <div class="font-semibold underline">Data wystawienia od</div>
      <p class="text-sm">
        ograniczenie sprawdzania polis, któych data wystawienia jest starsza niż ustawione pole. Eliminuje zaśmiecanie raportu niewypłaconymi polisami sprzed kilku lat.
        Domyślnie ustawiona jest data ostaniego dnia miesiąca sprzed roku.
      </p>
      <div class="font-semibold underline">Tolerancja</div>
      <p class="text-sm">
        procentowa, lub złotówkowa tolerancja, powyżej której raport mam wyświetlać niezgodność (zbyt mała prowizja lub jej brak). Domyslnie wartość ustawiana
        jest na 5% różnicy pomiędzy wpływem a należnością.
      </p>
      <div class="font-semibold underline">Założenia raportu</div>
      <ul class="list-outside list-disc ml-5 text-sm">
        <li>polisy ze statusami: Do odbioru, Do zwrotu, Do rozliczenia, Kompletna</li>
        <li>polisy z uzupełnionymi składkami i co najmniej jedną ratą</li>
        <li>polisy, które posiadają co najmniej jedną ratę z datą należności wcześniejszą niż ustawione pole [Należność na dzień] - pole obowiązkowe</li>
        <li>
          polisy, które datę wystawienia mają późniejszą niż ustawione pole [Data wystawienia od] - pole nieobowiązkowe -
          wyczyszczenie tego pola spowoduje wyświetlenie wszystkich polis niezależnie od daty wystawienia.
        </li>
        <li>polisy, któych różnica pomiędzy "należne" a wpłyneło jest większa od zadanej tolerancji, procentowej lub wartościuowej.</li>
        <li>polisy, których opiekunem jest wybrany agent (jeśli został wybrany z listy)</li>
        <li>polisy wystawione w wybranym Towarzystwie Ubezpieczeniowym (jesli zostało wybrane z listy)</li>
      </ul>
      <div class="text-base text-red-700 font-semibold">
        Uwaga! Ponieważ polisy grupowe mają składkę "kroczącą" nie będą wyświetlały się w obecnym raporcie jeśli suma prowizji jest większa
        bądź równa pierwszej składce. Proszę wziąć pod uwagę, że w przypadku tych polis raport jest w chwili obecnej nieskuteczny.
      </div>
    </template>
  </BaseModal>
</template>

<script>
export default {
  name: 'NullPaymentReportInfo',
};
</script>