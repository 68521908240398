<template>
  <div class="flex flex-col gap-y-2">
    <div id="nullpayment-report-header" class="flex flex-col gap-y-3 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div id="filters" class="flex flex-col sm:flex-row gap-x-3 gap-y-2">
        <div class="flex-grow flex flex-wrap">
          <div id="nullpayment-adv-filters-1" class="flex flex-wrap items-center gap-3">
            <div class="flex flex-wrap items-center gap-3">

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.report_date"
                labelAddon="Należność na dzień"
                :markDirty="true"
                :error="saveErrors.report_date"
              />

              <BaseInputWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                @enter="saveErrors = {}"
                type="date"
                v-model="activeFilters.min_sale_date"
                labelAddon="Data wystawienia od"
                :markDirty="true"
                :error="saveErrors.min_sale_date"
              />

              <div class="flex flex-nowrap">
                <BaseInputWithAddon
                  class="w-full md:min-w-max flex-0 sm:flex-1"
                  @enter="saveErrors = {}"
                  type="number"
                  v-model="activeFilters.lat_value"
                  labelAddon="Tolerancja"
                  :error="saveErrors.latitude"
                />
                <BaseSelectWithAddon
                  class="w-full md:min-w-max flex-0 sm:flex-1"
                  v-model="activeFilters.lat_type"
                  :options="[{id: 'percent', name: '%'}, {id: 'value', name: 'zł'}]"
                  :nullOption="false"
                />

              </div>

            </div>

            <div class="flex flex-wrap items-center gap-3">

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.employee_id"
                label="Agent"
                :options="employeeSimpleList"
                :nullOptionName="showAdmin ? 'wszyscy' : showManager ? 'zespół' : ''"
              />

              <BaseFilterSelectWithAddon
                class="w-full md:min-w-max flex-0 sm:flex-1"
                v-model="activeFilters.insurer_id"
                label="TU"
                :options="insurerList"
              />
            </div>

          </div>
        </div>

        <div id="clear-refresh-buttons" class="flex order-first sm:order-last">
          <div class="flex gap-0.5 justify-end items-start">
            <button @click="resetFilters" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="wyczyść wszystkie filtry">
              <FontAwesomeIcon icon="times" size="xs" /> <FontAwesomeIcon icon="filter" />
            </button>
            <button @click="initList" class="tw--btn tw--btn-filter shadow whitespace-nowrap" title="odśwież dane z serwera">
              <FontAwesomeIcon icon="sync-alt" size="xs" /> <FontAwesomeIcon icon="database" />
            </button>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap gap-x-3 gap-y-1">
        <button @click="getReport" class="tw--btn">Generuj raport</button>
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">znaleziono</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ rows.length }} rat</span>
        </div>
        <div class="w-full sm:w-auto flex flex-nowrap font-semibold text-gray-200 dark:text-gray-400">
          <span class="flex-grow sm:flex-grow-0 px-3 py-1 rounded-l bg-black">suma</span>
          <span class="px-3 py-1 rounded-r bg-blue-500 dark:bg-blue-900">{{ diffValueSum | moneyPL }}</span>
        </div>
      </div>
    </div>

    <div id="nullpayment-report-content" class="p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="mb-1">
        <span class="font-semibold">Raport prowizji niewypłaconych (wersja beta). <span class="text-yellow-500">Dostęp testowy dla administratorów aplikacji.</span></span>&nbsp;
        <a href="#" @click.prevent="infoModal = true" class="tw--link">Opis raportu</a>
      </div>

      <hr class="dark:border-gray-600 -mx-3 mb-1">

      <NullPaymentReportInfo  v-if="infoModal" @close="infoModal = false" />

      <div class="block lg:hidden text-red-700 text-base font-semibold">Raport jest obecnie w wersji beta i wyświetlany jest jedynie na ekranach o rozdzielczości minimum 1024px (szerokość)</div>
      <div id="nullpayment-report-table" class="hidden lg:flex flex-col gap-1 text-xs 2xl:text-sm divide-y dark:divide-gray-600">

        <!-- NAGŁÓWEK -->
        <div class="flex flex-wrap text-bright">
          <div class="w-full lg:w-1/2 flex flex-wrap">
            <div class="w-full lg:w-1/2 2xl:w-1/4">nr polisy</div>
            <div class="w-full lg:w-1/2 2xl:w-1/4">tow. ubezp.</div>
            <div class="w-full lg:w-1/2 2xl:w-1/4">agent</div>
            <div class="w-full lg:w-1/2 2xl:w-1/4">klient</div>
          </div>
          <div class="w-full lg:w-1/2 flex">
            <div class="flex-1 text-right self-center">składka</div>
            <div class="flex-1 text-right self-center">należne raty <FontAwesomeIcon  :icon="['far', 'question-circle']" class="tw--link" title="Suma należnych rat do dnia raportu" /></div>
            <div class="flex-1 text-right self-center">progn. prow. <FontAwesomeIcon  :icon="['far', 'question-circle']" class="tw--link" title="Cała należna prognozowana prowizja z polisy" /></div>
            <div class="flex-1 text-right self-center">należna. prow <FontAwesomeIcon  :icon="['far', 'question-circle']" class="tw--link" title="Należna prowizja na dzień raportu" /></div>
            <div class="flex-1 text-right self-center">wpłynęło <FontAwesomeIcon  :icon="['far', 'question-circle']" class="tw--link" title="Prowizja, która wpłynęła do dnia dzisiejszego (zaksięgowana)" /></div>
            <div class="flex-1 text-right self-center">różn.(%)</div>
            <div class="flex-1 text-right self-center">różn.(zł)</div>
          </div>
        </div>

        <!-- WIERSZE -->
        <div v-for="row in rows" :key="row.id" class="flex flex-wrap">
          <div class="w-full lg:w-1/2 flex flex-wrap">
            <div class="w-full lg:w-1/2 2xl:w-1/4">
              <router-link :to="{name: 'Order', params: {id: row.id}}" target="_blank" class="tw--link">{{ row.policy_no }}</router-link>
            </div>
            <div class="w-full lg:w-1/2 2xl:w-1/4">{{ row.insurer_name }}</div>
            <div class="w-full lg:w-1/2 2xl:w-1/4">{{ row.employee_name }}</div>
            <div class="w-full lg:w-1/2 2xl:w-1/4">{{ row.customer_name }}</div>
          </div>
          <div class="w-full lg:w-1/2 flex">
            <div class="flex-1 text-right self-center">{{ row.all_value | moneyPL }}</div>
            <div class="flex-1 text-right self-center">{{ row.sum_inst | moneyPL }}</div>
            <div class="flex-1 text-right self-center">{{ row.all_income | moneyPL }}</div>
            <div class="flex-1 text-right self-center">{{ row.expect_provision | moneyPL }}</div>
            <div class="flex-1 text-right self-center">{{ row.real_income | moneyPL }}</div>
            <div class="flex-1 text-right self-center">{{ row.diff_percent | percentPL }}%</div>
            <div class="flex-1 text-right self-center">{{ row.diff_value | moneyPL }}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { errorHandler } from '../../mixins/errorHandler';
import moment from 'moment';
import ProvisionService from '../../services/ProvisionService';
import NullPaymentReportInfo from './NullPaymentReportInfo.vue';

export default {
  name: 'NullPaymentReport',
  components: { NullPaymentReportInfo },
  mixins: [errorHandler],
  data() {
    return {
      rows: [],
      activeFilters: {
        report_date: '',
        min_sale_date: '',
        employee_id: '',
        insurer_id: '',
        lat_type: '',
        lat_value: '',
      },
      loading: false,
      helpDescription: false,
      saveErrors: {},
      infoModal: false,
    };
  },
  computed: {
    ...mapGetters([
      'currentUserType',
    ]),
    ...mapGetters('products', [
      'insurerList',
    ]),
    ...mapState('employees', [
      'employeeSimpleList',
    ]),
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
    showManager() {
      return this.currentUserType === 'manager';
    },
    expectSum() {
      return this.rows.reduce((a, b) => ({ expect_provision: Number(a.expect_provision) + Number(b.expect_provision) }), { expect_provision: 0 }).expect_provision;
    },
    incomeSum() {
      return this.rows.reduce((a, b) => ({ real_income: Number(a.real_income) + Number(b.real_income) }), { real_income: 0 }).real_income;
    },
    diffValueSum() {
      return this.incomeSum - this.expectSum;
    },
    diffPercentAvg() {
      return (((this.incomeSum / this.expectSum) - 1) * 100).toFixed(2);
    },
  },
  mounted() {
    this.$store.dispatch('employees/getEmployeeSipleList').catch(error => this.resolveError(error));
    if (this.insurerList.length === 0) this.$store.dispatch('products/getInsurerList').catch(error => this.resolveError(error));
    this.initList();
  },
  methods: {
    initList() {
      // Filtry
      Object.keys(this.activeFilters).forEach((key) => {
        if (this.$route.query[key] !== undefined && this.$route.query[key] !== '') {
          this.activeFilters[key] = decodeURI(this.$route.query[key]);
        }
      });
      if (this.$route.query.report_date === undefined || this.$route.query.report_date === '') {
        this.setInitialReportDates();
      }
      this.getReport();
    },
    getReport() {
      this.$store.dispatch('setLoadingData', true);
      const params = {};
      Object.assign(params, this.activeFilters);
      ProvisionService.getNullPaymentReport(params)
        .then((response) => {
          this.rows = response.data.rows;
          this.$store.dispatch('setLoadingData', false);
          this.setUrl();
        })
        .catch((error) => {
          this.rows = [];
          this.saveErrors = this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
          this.setUrl();
        });
    },
    prevMonth() {
      if (this.activeFilters.report_date !== '') {
        this.activeFilters.report_date = moment(this.activeFilters.report_date).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      }
    },
    setInitialReportDates() {
      this.activeFilters.report_date = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
      this.activeFilters.min_sale_date = moment().subtract(1, 'years').endOf('month').format('YYYY-MM-DD');
      this.activeFilters.lat_type = 'percent';
      this.activeFilters.lat_value = '5';
    },
    nextMonth() {
      if (this.activeFilters.report_date !== '') {
        this.activeFilters.report_date = moment(this.activeFilters.report_date).add(1, 'months').endOf('month').format('YYYY-MM-DD');
      }
    },
    resetFilters() {
      Object.keys(this.activeFilters).forEach((key) => {
        this.activeFilters[key] = '';
      });
      this.saveErrors = {};
      this.setInitialReportDates();
      this.getReport();
    },
    setUrl() {
      const query = {};
      Object.keys(this.activeFilters).forEach((key) => {
        query[key] = encodeURI(this.activeFilters[key]);
      });
      if (JSON.stringify(this.$route.query) !== JSON.stringify(query)) {
        this.$router.replace({ name: 'NullPaymentReport', query });
      }
    },
  },
};
</script>
