import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  getTmpProvisions() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-tmp-provisions')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  checkProvisionsTmpBackup() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/provisions-tmp/check-backup')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  restoreProvisionTmpFromBackup() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/provisions-tmp/restore')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  uploadProvisionsFile(formData) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/upload-provision-csv', formData)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  autoStoreProvision(form) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/auto-store-provision', form)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  truncateTmpProvisions() {
    const result = new Promise((resolve, reject) => {
      apiClient.delete('/api/truncate-tmp-provisions')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  deleteTmpProvisionsNotLinked() {
    const result = new Promise((resolve, reject) => {
      apiClient.delete('/api/delete-tmp-provisions-not-linked')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  deleteTmpProvision(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/delete-tmp-provision/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getListPaydProvisions(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-list-payd-provisions', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getNullPaymentReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-nullpayment-report', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
